import React, { useState, useEffect } from 'react';
import { faChartLine, faFileLines, faGear, faTachometerAverage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import { faBars, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from 'react-bootstrap';
import DarkMode from "./DarkMode";


const Sidebar = (props) => {


  const { isDarkMode, isSidebarCollapsed } = props;
  const location = useLocation();


  const [activeMenu, setActiveMenu] = useState(null);

  const toggleMenu = (menu) => {
    setActiveMenu(activeMenu === menu ? null : menu);
  };

  /*  const renderSubmenu = (submenuItems) => {
     return (
       <ul className="submenu">
         {submenuItems.map((item, index) => (
           <li key={index}>
             <Link to={item.link}>{item.title}</Link>
           </li>
         ))}
       </ul>
     );
   }; */

   const renderSubmenu = (submenuItems) => {
    const renderSubMenuItems = (items) => {
      return (
        <ul className="submenu">
          {items.map((item, index) => (
            <li key={index}>
              {item.link ? (
                <Link to={item.link} className={item.font}>{item.title}</Link>
              ) : (
                <span className={item.font}>{item.title}</span>
              )}
              {item.submenu && renderSubMenuItems(item.submenu)}
            </li>
          ))}
        </ul>
      );
    };
  
    return renderSubMenuItems(submenuItems);
  };
  



  const sidebarClass = isSidebarCollapsed ? "close" : "open";


  return (
    <>
      <div className={`d-flex flex-column   ${sidebarClass} shadow-lg p-3  sidebar`}  >
        <div className='d-flex justify-content-end'>



        </div>

        {isDarkMode ? (
          <img src='../../assets/images/logo_white.svg' alt="logo" className="logo mb-4" />
        ) : (
          <img src='../../assets/images/logo_black.svg' alt="logo" className="logo mb-4" />
        )}
        <ul className={`nav ${isSidebarCollapsed ? '' : 'nav-pills'} flex-column mb-auto `}>

        <li className='text-left text-truncate'>
            <Link to="/" className="nav-link link-dark ps-2  vertical-center home-icon" aria-current="page">
              <img src="../../assets/images/home-icon.svg" alt="Home Icon" className='pe-1' />
              <span className="text">{isSidebarCollapsed ? '' : 'Home'}</span>
            </Link>  
          </li>
          <li className='text-left text-truncate'>
            <Link to="/dashboard" className="nav-link link-dark ps-2 dashboard-icon vertical-center" aria-current="page">
              <img src="../../assets/images/dashboard-icon.svg" alt="Dashboard Icon" className='pe-1' />
              <span className="text">{isSidebarCollapsed ? '' : 'Dashboard'}</span>
            </Link>  
          </li>


          <li className="text-left text-truncate">
              <div className="nav-link link-dark ps-2 customer-icon vertical-center" onClick={() => toggleMenu('customer')}>
                <img src="../../assets/images/customer-icon.svg" className="pe-1" alt='' />
                <span className="text">{isSidebarCollapsed ? '' : 'Customer Management'}</span>
              </div>
              {activeMenu === 'customer' && renderSubmenu([
                { title: 'Consumer', link: '/consumer' },
                { title: 'Customer360', link: '/customer360' },
                { title: 'Location', link: '/location' },
                { title: 'Commitment Contract', link: '/commitment' },
                { title: 'Site', link: '/site' },
              ])}
            </li>


            <li className="text-left text-truncate">
              <div className="nav-link link-dark ps-2 meter-icon vertical-center" onClick={() => toggleMenu('meter')}>
                <img src="../../assets/images/meter-icon.svg" className="pe-1" alt='' />
                <span className="text">{isSidebarCollapsed ? '' : 'Meter Management'}</span>
              </div>
              {activeMenu === 'meter' && renderSubmenu([
                { title: 'Meter', link: '/meters' },
                { title: 'Meter Type', link: '/metertype' },
                { title: 'Install Meter', link: '/installmeter' },
                { title: 'Register Type', link: '/registertype' },
                { title: 'Register Rules', link: '/registerrules' },
                { title: 'Register Rule Book', link: '/registerrulebook' },
                { title: 'Register Rule Mixer', link: '/registerrulemixer' },
              ])}
            </li>



          <li className="text-left text-truncate">
            <div className="nav-link link-dark ps-2 read-icon vertical-center" onClick={() => toggleMenu('read')}>
              <img src="../../assets/images/read-icon.svg" className="pe-1" alt='' />
              <span className="text">{isSidebarCollapsed ? '' : 'Read Management'}</span>
            </div>
            {activeMenu === 'read' && renderSubmenu([
              { title: 'Read', link: '/readdetails' },
              { title: 'Read Cluster', link: '/readcluster' }
            ])}
          </li>

          <li className="text-left text-truncate">
            <div className="nav-link link-dark ps-2 vee-icon vertical-center" onClick={() => toggleMenu('vee')}>
              <img src="../../assets/images/vee-icon.svg" className="pe-1" alt='' />
              <span className="text">{isSidebarCollapsed ? '' : 'VEE'}</span>
            </div>
            {activeMenu === 'vee' && renderSubmenu([
              { title: 'VEE Cluster', link: '/veecluster' },
              { title: 'VEE Rules', link: '/veerule' },
              { title: 'VEE Rule Builder', link: '/veerulebuilder' },
              { title: 'VEE Rule Workflow', link: '/veeruleworkflow' },
            ])}
          </li>

          <li className="text-left text-truncate">
            <div className="nav-link link-dark ps-2 billing-icon vertical-center" onClick={() => toggleMenu('billing')}>
              <img src="../../assets/images/billing-icon.svg" className="pe-1" alt='' />
              <span className="text">{isSidebarCollapsed ? '' : 'Billing Determinant'}</span>
            </div>
            {activeMenu === 'billing' && renderSubmenu([
              { title: 'Billing Determinant', link: '/billingdeterminant' },
              { title: 'Billing Cluster', link: '/billingcluster' },
              { title: 'Wallet Balance', link: '/walletbalance' },

            ])}

          </li>

          <li className="text-left text-truncate">
            <div className="nav-link link-dark ps-2 tickets-icon vertical-center" onClick={() => toggleMenu('tickets')}>
              <img src="../../assets/images/tickets-icon.svg" className="pe-1" alt='' />
              <span className="text">{isSidebarCollapsed ? '' : 'Tickets'}</span>
            </div>
            {activeMenu === 'tickets' && renderSubmenu([
              { title: 'Tickets', link: '/ticketdetails' },
            ])}
          </li>


          <li className="text-left text-truncate">
            <div className="nav-link link-dark ps-2 exception-icon vertical-center" onClick={() => toggleMenu('exception')}>
              <img src="../../assets/images/exception-icon.svg" className="pe-1" alt='' />
              <span className="text">{isSidebarCollapsed ? '' : 'Exception Management'}</span>
            </div>
            {activeMenu === 'exception' && renderSubmenu([
              { title: 'Exception Management', link: '/exceptiondetails' },
            ])}
          </li>

          <li className="text-left text-truncate">
            <div className="nav-link link-dark ps-2 asset-icon vertical-center" onClick={() => toggleMenu('asset')}>
              <img src="../../assets/images/asset-icon.png" className="pe-1" alt='' />
              <span className="text">{isSidebarCollapsed ? '' : 'Asset Management'}</span>
            </div>
         
            {activeMenu === 'asset' && renderSubmenu([
               {
                title: 'Asset Inventory', link: null , font:'fw-bold',
                submenu: [

                  { title: 'Assets Dashboard', link: '/assetdashboard' },
                  { title: 'Enhanced Inventory', link: '/enhancedinventory' },                 
                  { title: 'Assets Reports', link: '/assetreports' },
                  { title: 'Lifecycle Config', link: '/lifecycleconfig' },
                  { title: 'Assets Details', link: '/assetdetails' },
                  { title: 'Maintenance Scheduling', link: '/assetmaintenance' },
                ],
              },
              /* {
                title: 'Meter Information Management', link: null , font:'fw-bold',
                submenu: [
                  { title: 'Installed Meter Locations', link: '/' },
                  { title: 'Consumer Information', link: '/' },
                  { title: 'Meter Types and Configurations', link: '/' },
                ],
              },
              {
                title: 'Asset Tracking', link: null , font:'fw-bold',
                submenu: [
                  { title: 'Status Tracking', link: '/' },
                  { title: 'In-Service Asset History', link: '/' },
                  { title: 'Field Deployment Monitoring', link: '/' },
                ]
              },
              {
                title: 'Damage & Deterioration Logging', link: null, font:'fw-bold',
                submenu: [
                  { title: 'Damage Reporting', link: '/' },
                  { title: 'Deterioration Logging', link: '/' },
                  { title: 'Maintenance and Repair History Tracking', link: '/' },
                ]
              } */
            ])}



            
          </li>

          <li className="text-left text-truncate">
            <Link to={"/networkdashboard"} className="nav-link link-dark ps-2 reports-icon vertical-center" onClick={() => toggleMenu('network')}>
              <img src="../../assets/images/network-icon.png" className="pe-1" alt='' />
              <span className="text">{isSidebarCollapsed ? '' : 'Network Management'}</span>
              </Link>           
            {/* {activeMenu === 'network' && renderSubmenu([
              {
                title: 'Graphical Network View', link: '', font:'fw-bold',
                submenu: [
                  { title: 'Network Configuration', link: '/' },
                  { title: 'Substations', link: '/' },
                  { title: 'Transformers', link: '/' },
                  { title: 'Feeders', link: '/' },
                  { title: 'Switches', link: '/' },
                  { title: 'Meters', link: '/' },
                ],
              },
              {
                title: 'Network Configuration', link: '', font:'fw-bold',
                submenu: [
                  { title: 'Switch Position Modification', link: '/' },
                  { title: 'Field Input Integration', link: '/' },
                  { title: 'SCADA System Integration', link: '/' },
                ]
              },
              {
                title: 'Power Flow Analysis', link: '',font:'fw-bold',
                submenu: [
                  { title: 'Real-time Analysis', link: '/' },
                  { title: 'Historical Analysis', link: '/' },
                  { title: 'Switch Condition Monitoring', link: '/' },
                ]
              },
              {
                title: 'Energy Consumption Monitoring', link: '',font:'fw-bold',
                submenu: [
                  { title: 'Recording Energy Consumption Data', link: '/' },
                  { title: 'Energy Accounting', link: '/' },
                  { title: 'Data Consideration for Meter Offline Times', link: '/' },
                ]
              }
            ])} */}
           
          </li>

          <li className="text-left text-truncate">
            <div>
              <Link to={"/reports"} className="nav-link link-dark ps-2 reports-icon vertical-center" onClick={() => toggleMenu('reports')}>
                <img src="../../assets/images/reports-icon.png" className="pe-1" alt='' />
                <span className="text" >{isSidebarCollapsed ? '' : 'Reports'}</span>
              </Link>
            </div>
            {activeMenu === 'reports' && renderSubmenu([

              { title: 'SAIDI Report', link: '/saidireport' },
              { title: 'SAIFI Report', link: '/saifireport' },
              { title: 'CAIDI Report', link: '/caidireport' },
              { title: 'CAIFI Report', link: '/caifireport' },
              { title: 'MAIFI Report', link: '/maifireport' },

              
              { title: 'Billing Report', link: '/billingreport' }, 
              { title: 'Billing Summary', link: '/billingsummary' }, 
              { title: 'Consumption variance', link: '/consumptionvariancereport' }, 
              { title: 'Energy Audit/loss', link: '/energyauditlossreport' }, 
             
              
              { title: 'Reporting Dashboard', link: '/reportingdashboard' },
               { title: 'Exception Report', link: '/exceptionreport' },
               { title: 'Weather Report', link: '/weatherreport' },
               { title: 'Reconciliation Report', link: '/reconciliationreport' },

               { title: 'Meter Command Report', link: '/metercommandreport' },
              
               { title: 'Interface Performance Report', link: '/interfaceperformancereport' },
               { title: 'AMI Performance Report', link: '/amiperformancereport' },
               { title: 'Energy Performance Report', link: '/energyperformancereport' },

            
               { title: 'DER Integration Dashboard', link: '/derintegrationdashboard' },
               { title: 'No Reads Dashboard', link: '/noreadsdashboard' },
               { title: 'Missing Reads Dashboard', link: '/missingreadsdashboard' },
               { title: 'Unexpected Reads Dashboard', link: '/unexpectedreadsdashboard' },
               { title: 'Interface Exception Report', link: '/interfaceexceptionreport' },   
               { title: 'Theft Flags Report', link: '/theftflagsreport' },
               { title: 'Revenue Protection Report', link: '/revenueprotectionreport' },
              { title: 'Meter Reading Report', link: '/meterreadingreport' },   
              { title: 'MDM Performance Report', link: '/mdmperformancereport' },   
              { title: 'Meter Event Report', link: '/metereventreport' },
              { title: 'Meter Inventory Report', link: '/meterinventoryreport' },                 
              { title: 'Load Forecasting Report', link: '/loadforecastingreport' },
              { title: 'Billing Determinant Report', link: '/billingdeterminantreport' },
              { title: 'Midnight Consumption Report', link: '/midnightconsumptionreport' },
              { title: 'Monthly Consumption Report', link: '/monthlyconsumptionreport' },
              { title: 'VEE Exceptions Report', link: '/veeexceptionsreport' },  
              { title: 'Unauthorized Usage Report', link: '/unauthorizedusagereport' },   
              { title: 'Missing Reads Report', link: '/missingreadsreport' },        
              { title: 'Load Profile Data Report', link: '/loadprofiledatareport' },
              { title: 'Daily Data Collection Report', link: '/dailydatacollectionreport' },
              { title: 'Excessive Missing Reads Report', link: '/excessivemissingreadsreport' },
              { title: 'Estimation Failure Detail Report', link: '/estimationfailuredetailreport' },
              { title: 'Missing Interval Aging Report', link: '/missingintervalagingreport' },
              { title: 'Physical Meter Events Report', link: '/physicalmetereventsreport' },
          
              
            ])}
          </li>

          <li className="text-left text-truncate">
          <div>
              <Link to={"/workorders"} className="nav-link link-dark ps-2 wo-icon vertical-center" onClick={() => toggleMenu('workorders')}>
                <img src="../../assets/images/wo-icon.png" className="pe-1" alt='' />
                <span className="text" >{isSidebarCollapsed ? '' : 'Workorders'}</span>
              </Link>
            </div>
            {activeMenu === 'workorders' && renderSubmenu([
              { title: 'Disconnect Meter', link: '/disconnectmeter' },
              { title: 'Reconnect Meter', link: '/reconnectmeter' },
              { title: 'On Demand Read', link: '/ondemandread' },
            ])}
          </li>



          <li className="text-left text-truncate">
            <div className="nav-link link-dark ps-2 settings-icon vertical-center" onClick={() => toggleMenu('settings')}>
              <img src="../../assets/images/settings-icon.svg" className="pe-1" alt='' />
              <span className="text">{isSidebarCollapsed ? '' : 'Settings'}</span>
            </div>
            {activeMenu === 'settings' && renderSubmenu([
              { title: 'User Management', link: '/usermanagement' },
              { title: 'Group Management', link: '/groupmanagement' },
            ])}
          </li>

          <li className="text-left text-truncate">
            <div className="nav-link link-dark ps-2 p2p-icon vertical-center" onClick={() => toggleMenu('p2p')}>
              <img src="../../assets/images/p2p-icon.svg" className="pe-1" alt='' />
              <span className="text">{isSidebarCollapsed ? '' : 'P2P'}</span>
            </div>
            {activeMenu === 'p2p' && renderSubmenu([
              { title: 'Prosumer Dashboard', link: '/p2p/prosumerdashboard' },
              { title: 'Prosumer Scheduling', link: '/p2p/prosumerscheduling' },
              { title: 'Consumer Registration', link: '/p2p/consumerregistration' },
              { title: 'Service Provider', link: '/p2p/serviceprovider' },
              { title: 'Real Time Dashboard', link: '/p2p/realtimedashboard' },
              { title: 'Energy Block Dashboard', link: '/p2p/energyblockdashboard' },
            ])}
          </li>
        </ul>

      </div>


    </>

  )
}

export default Sidebar