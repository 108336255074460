import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import NoPage from "./components/NoPage";
import Sidebar from "./components/Sidebar";
import { GoogleOAuthProvider } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import DarkMode from "./components/DarkMode";
import Login from "./components/Login";
import { Provider } from "react-redux";
import store from "./redux/store"; // Import the Redux store
import LoadingBar from 'react-top-loading-bar'






const Home = lazy(() => import("./components/Home"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const Consumermaster = lazy(() => import("./components/consumer/consumermaster"));
const Customer360 = lazy(() => import("./components/consumer/customer360"));

const Locationmaster = lazy(() => import("./components/location/locationmaster"));
const Contractmaster  = lazy(() => import( "./components/commitment/contractmaster"));
const Sitemaster = lazy(() => import("./components/site/sitemaster"));
const Metermaster = lazy(() => import( "./components/meters/metermaster"));
const Metertype = lazy(() => import( "./components/metertype/metertype"));
const Installmeter = lazy(() => import( "./components/installmeter/installmeter"));
const RegisterType = lazy(() => import( "./components/registertype/registerType"));
const Register = lazy(() => import( "./components/meterregister/register"));
const ReadDetails = lazy(() => import( "./components/read/readDetails"));
const ReadCluster = lazy(() => import( "./components/readcluster/readCluster"));
const VeeCluster  = lazy(() => import( "./components/vee/veecluster"));
const Veerule  = lazy(() => import( "./components/vee/veerule"));
const Billingdeterminant  = lazy(() => import( "./components/billingdeterminant/billingdeterminant"));
const Billingcluster  = lazy(() => import( "./components/billingdeterminant/billingcluster"));
const WalletBalance  = lazy(() => import( "./components/billingdeterminant/WalletBalance"));
const Exceptiondetails  = lazy(() => import( "./components/exceptionmanagement/exceptiondetails"));
const Ticketdetails  = lazy(() => import( "./components/tickets/ticketdetails"));
const Registerrules  = lazy(() => import( "./components/registerrules/registerRules"));
const Registerrulebook  = lazy(() => import( "./components/registerrulebook/registerRuleBook"));
const Registerrulemixer  = lazy(() => import( "./components/registerrulemixer/registerRuleMixer"));
const Disconnectmeter  = lazy(() => import( "./components/workorders/Disconnectmeter"));
const Reconnectmeter  = lazy(() => import( "./components/workorders/Reconnectmeter"));
const Workorders  = lazy(() => import( "./components/workorders/Workorders"));

const Ondemandread  = lazy(() => import( "./components/workorders/Ondemandread"));
const Reportsmaster = lazy(() => import( "./components/reports/Reportsmaster"));
const Meterreadingreport = lazy(() => import( "./components/reports/Meterreadingreport"));
const Exceptionreport = lazy(() => import( "./components/reports/Exceptionreport"));
const Loadforecastingreport = lazy(() => import( "./components/reports/Loadforecastingreport"));
const Billingdeterminantreport = lazy(() => import( "./components/reports/Billingdeterminantreport"));
const BillingReport = lazy(() => import( "./components/reports/BillingReport"));
const BillingSummary = lazy(() => import( "./components/reports/BillingSummary"));
const ConsumptionVarianceReport = lazy(() => import( "./components/reports/ConsumptionVarianceReport"));
const MeterCommandReport = lazy(() => import( "./components/reports/MeterCommandReport"));
const InterfacePerformanceReport = lazy(() => import( "./components/reports/InterfacePerformanceReport"));
const AMIPerformanceReport = lazy(() => import( "./components/reports/AMIPerformanceReport"));
const EnergyPerfReport = lazy(() => import( "./components/reports/EnergyPerfReport"));
const EnergyAuditLossReport = lazy(() => import( "./components/reports/EnergyAuditLossReport"));

const SAIDIReport = lazy(() => import( "./components/reports/SAIDIReport"));
const SAIFIReport = lazy(() => import( "./components/reports/SAIFIReport"));
const CAIDIReport= lazy(() => import( "./components/reports/CAIDIReport"));
const CAIFIReport = lazy(() => import( "./components/reports/CAIFIReport"));
const MAIFIReport = lazy(() => import( "./components/reports/MAIFIReport"));


const Midnightconsumptionreport = lazy(() => import( "./components/reports/Midnightconsumptionreport"));
const Monthlyconsumtionreport = lazy(() => import( "./components/reports/Monthlyconsumtionreport"));
const Loadprofiledatareport = lazy(() => import( "./components/reports/Loadprofiledatareport"));
const Veeexceptionsreport = lazy(() => import( "./components/reports/Veeexceptionsreport"));
const Metereventreport = lazy(() => import( "./components/reports/Metereventreport"));
const Meterinventoryreport = lazy(() => import( "./components/reports/Meterinventoryreport"));
const Networkdashboard = lazy(() =>import( "./components/networkmanagement/Networkdashboard"))
const DerIntegrationDashboard = lazy(() =>import( "./components/reports/Derintegrationdashboard"))
const MissingReadsDashboard = lazy(() =>import( "./components/reports/MissingReadsDashboard"))
const UnexpectedReadsDashboard = lazy(() =>import( "./components/reports/UnexpectedReadsDashboard"))


const Excessivemissingreadsreport = lazy(() =>import( "./components/reports/Excessivemissingreadsreport"))
const MissingIntervalAgingReport = lazy(() =>import( "./components/reports/Missingintervalagingreport"))
const MissingReadsReport = lazy(() =>import( "./components/reports/Missingreadsreport"))
const DailyDataCollectionReport = lazy(() =>import( "./components/reports/Dailydatacollectionreport"))
const EstimationFailureDetailReport = lazy(() =>import( "./components/reports/Estimationfailuredetailreport"))
const ReconciliationReport = lazy(() =>import( "./components/reports/Reconciliationreport"))
const PhysicalMeterEventsReport = lazy(() =>import( "./components/reports/PhysicalMeterEventsReport"))
const InterfaceExceptionReports = lazy(() =>import( "./components/reports/InterfaceExceptionReports"))
const MDMPerformanceReport = lazy(() =>import( "./components/reports/MDMPerformanceReport"))
const TheftFlagsReport = lazy(() =>import( "./components/reports/TheftFlagsReport"))
const UnauthorizedUsageReport = lazy(() =>import( "./components/reports/UnauthorizedUsageReport"))
const RevenueProtectionReport = lazy(() =>import( "./components/reports/RevenueProtectionReport"))
const NoReadsDashboard = lazy(() =>import( "./components/reports/NoReadsDashboard"))



const Echart = lazy(() => import( "./components/echarts/echart"));
const Usermanagement = lazy(() => import( "./components/settings/usermanagement"));
const Groupmanagement = lazy(() => import( "./components/settings/groupmanagement"));
const Jsonrules = lazy(() => import( "./components/vee/jsonrules"));
const Veeworkflow = lazy(() => import( "./components/vee/veeworkflow"));

const Enhancedinventory = lazy(() => import( "./components/assetmanagement/Enhancedinventory"));
const Assetdashboard = lazy(() => import( "./components/assetmanagement/Assetdashboard"));
const Assetreports = lazy(() => import( "./components/assetmanagement/Assetreports"));
const Lifecycleconfig = lazy(() => import( "./components/assetmanagement/Lifecycleconfig"));
const Assetdetails = lazy(() => import( "./components/assetmanagement/Assetdetails"));
const Assetmaintenance = lazy(() => import( "./components/assetmanagement/Assetmaintenance"));


const EventDashboard = lazy(() => import("./components/EventDashboard"));
const AssetManagement = lazy(() => import("./components/AssetManagement"));
const ViewUsers = lazy(() => import("./components/ViewUsers"));
const MonthlyBilling = lazy(() => import("./components/MonthlyBilling"));
const VeeReport = lazy(() => import("./components/VeeReport"));
const DailyBilling = lazy(() => import("./components/DailyBilling"));
const CreateUser = lazy(() => import("./components/CreateUser"));
const CreateAsset = lazy(() => import("./components/CreateAsset"));
const LoadProfilePatterns = lazy(() => import("./components/LoadProfilePatterns"));
const NetMeteringBilling = lazy(() => import("./components/NetMeteringBilling"));
const DeviceProfiles = lazy(() => import("./components/DeviceProfiles"));
const CustomerDevices = lazy(() => import("./components/CustomerDevices"));
const TenantDevices = lazy(() => import("./components/TenantDevices"));
const Telemetry = lazy(() => import("./components/telemetry/Telemetry"));
const Weatherreport = lazy(() => import("./components/reports/Weatherreport"));
const Reportingdashboard = lazy(() => import("./components/reports/Reportingdashboard"));

const ProsumerDashboard = lazy(() => import("./components/p2p/prosumerDashboard"));
const ConsumerRegistration = lazy(() => import("./components/p2p/consumerRegistration"));
const ServiceProvider = lazy(() => import("./components/p2p/serviceProvider"));
const ProsumerScheduling = lazy(() => import("./components/p2p/prosumerScheduling"));
const RealTimeDashboard = lazy(() => import("./components/p2p/realTimeDashboard"));
const EnergyBlockDahsboard = lazy(() => import("./components/p2p/energyBlockDahsboard"));


function App() {

  const ref = useRef(null);


  


  const API_KEY = "364474069693-uvs67jjv58ufjq0cadmftddl63k4mfvf.apps.googleusercontent.com";
  const [credentialResponse, setCredentialResponse] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (ref.current) {
      ref.current.continuousStart();
    }
    setTimeout(() => {
      if (ref.current) {
        ref.current.complete();
      }
      setIsLoading(false);
    }, 3000);
  }, []);

  let responsePayload;
  if (credentialResponse) {
    responsePayload = jwtDecode(credentialResponse.credential);

    responsePayload.role = "tenant";

    const users = JSON.parse(localStorage.getItem("users")) || [];

    if (Array.isArray(users)) {
      const existingUser = users.find((user) => user.email === responsePayload.email);

      if (existingUser) {
        Object.assign(existingUser, responsePayload);
      } else {
        users.push(responsePayload);
      }

    } else {
      console.log("Users is not an array");
    }
  }


  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);


  const handleClick =() =>{
    setIsSidebarCollapsed(!isSidebarCollapsed);
  }


  //Login code
  
  const [authToken, setAuthToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [loginresponse, setLoginResponse] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
   const authToken =  localStorage.getItem("authToken");

   if (authToken) {
    setLoginResponse(true);
  } else {
    setLoginResponse(false);
  }

    setTimeout(() => {
      setLoading(false);
     
    }, 2000);
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center mt-4"><div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div></div>
    );
  }

 



  const handleLogin = async (authToken, refreshToken) => {
    // Set loading to true to indicate that the login process is in progress
    setLoading(true);
  
    try {
      setAuthToken(authToken);
      setRefreshToken(refreshToken);
  
      // Save tokens to localStorage
      localStorage.setItem("authToken", authToken);
      localStorage.setItem("refreshToken", refreshToken);
  
      // Update login response based on authToken
      if (authToken) {
        setLoginResponse(true);
      } else {
        setLoginResponse(false);
      }
    } catch (error) {
      console.error("Error during login:", error);
      // Handle the error, e.g., set an error state or display an error message
    } finally {
      // Set loading back to false, whether the login was successful or not
      setLoading(false);
    }
  };
  
  




  
 
 
  return (
    <Provider store={store}>
    <BrowserRouter>
      <GoogleOAuthProvider clientId={API_KEY}>
        {!loginresponse ? (
                   
                   <Login handleLogin={handleLogin} />

        ) : (

          <div className="wrapper">
                  <LoadingBar color="#007bff" ref={ref} shadow={false} />

            <Sidebar isSidebarCollapsed={isSidebarCollapsed} />
            <div className="right-container ">
            

              <Navbar isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} handleClick={handleClick}   username={loginresponse.username} />
              <Suspense fallback={<div className="d-flex justify-content-center mt-4"><div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div></div>}>
                <Routes>
                <Route path="/" element={<Home />} />
                  <Route exact path="/dashboard" element={<Dashboard />} />
                  <Route exact path="/consumer" element={<Consumermaster/>} />
                  <Route exact path="/customer360" element={<Customer360/>} />
                  <Route exact path="/location" element={<Locationmaster/>} />
                  <Route exact path="/site" element={<Sitemaster/>} />
                  <Route exact path="/meters" element={<Metermaster/>} />
                  <Route exact path="/metertype" element={<Metertype/>} />
                  <Route exact path="/installmeter" element={<Installmeter/>} />
                  <Route exact path="/registertype" element={<RegisterType/>} />
                 
                  <Route exact path="/register" element={<Register/>} />
                  <Route exact path="/readdetails" element={<ReadDetails/>} />

                  <Route exact path="/readcluster" element={<ReadCluster/>} />
                  <Route exact path="/veecluster" element={<VeeCluster />} />

                  <Route exact path="/veerule" element={<Veerule />} />

                  <Route exact path="/disconnectmeter" element={<Disconnectmeter />} />
                  <Route exact path="/workorders" element={<Workorders />} />
                  <Route exact path="/reconnectmeter" element={<Reconnectmeter />} />
                  <Route exact path="/ondemandread" element={<Ondemandread />} />

                  <Route exact path="/billingdeterminant" element={<Billingdeterminant />} />
                  
                  <Route exact path="/billingcluster" element={<Billingcluster />} />
                  <Route exact path="/walletbalance" element={<WalletBalance />} />


                  <Route exact path="/registerrules" element={<Registerrules />} />
                  <Route exact path="/registerrulebook" element={<Registerrulebook />} />
                  <Route exact path="/registerrulemixer" element={<Registerrulemixer />} />

                  <Route exact path="/exceptiondetails" element={<Exceptiondetails />} />
                  <Route exact path="/ticketdetails" element={<Ticketdetails />} />
                  <Route exact path="/echart" element={<Echart />} />

                  <Route exact path="/commitment" element={<Contractmaster/>} />
                  <Route exact path="/eventdashboard" element={<EventDashboard />} />
                  <Route exact path="/assetmanagement" element={<AssetManagement />} />
                  <Route exact path="/viewusers" element={<ViewUsers />} />
                  <Route exact path="/monthlybilling" element={<MonthlyBilling />} />
                  <Route exact path="/veereport" element={<VeeReport />} />
                  <Route exact path="/dailybilling" element={<DailyBilling />} />
                  <Route exact path="/loadprofilepatterns" element={<LoadProfilePatterns />} />
                  <Route exact path="/netmeteringbilling" element={<NetMeteringBilling />} />
                  <Route exact path="/createuser" element={<CreateUser />} />
                  <Route exact path="/createasset" element={<CreateAsset />} />
                  <Route exact path="/devicemanagement" element={<DeviceProfiles />} />
                  <Route exact path="/customerdevices" element={<CustomerDevices />} />
                  <Route exact path="/tenantdevices" element={<TenantDevices />} />
                  <Route exact path="/telemetry" element={<Telemetry />} />

                  <Route exact path="/enhancedinventory" element={<Enhancedinventory />} />
                  <Route exact path="/assetdashboard" element={<Assetdashboard />} />
                  <Route exact path="/assetreports" element={<Assetreports />} />
                  <Route exact path="/lifecycleconfig" element={<Lifecycleconfig />} />
                  <Route exact path="/assetdetails" element={<Assetdetails />} />
                  <Route exact path="/assetmaintenance" element={<Assetmaintenance />} />



                  <Route exact path="/reports" element={<Reportsmaster />} />
                  <Route exact path="/meterreadingreport" element={<Meterreadingreport />} />
                  <Route exact path="/midnightconsumptionreport" element={<Midnightconsumptionreport />} />
                  <Route exact path="/monthlyconsumptionreport" element={<Monthlyconsumtionreport />} />
                  <Route exact path="/loadprofiledatareport" element={<Loadprofiledatareport />} />
                  <Route exact path="/veeexceptionsreport" element={<Veeexceptionsreport />} />
                  <Route exact path="/metereventreport" element={<Metereventreport />} />
                  <Route exact path="/meterinventoryreport" element={<Meterinventoryreport />} />
                  <Route exact path="/weatherreport" element={<Weatherreport />} />
                  <Route exact path="/reportingdashboard" element={<Reportingdashboard />} />
                  <Route exact path="/derintegrationdashboard" element={<DerIntegrationDashboard />} />
                  <Route exact path="/missingreadsdashboard" element={<MissingReadsDashboard />} />
                  <Route exact path="/unexpectedreadsdashboard" element={<UnexpectedReadsDashboard />} />
                  <Route exact path="/noreadsdashboard" element={<NoReadsDashboard />} />
                  <Route exact path="/billingreport" element={<BillingReport />} />
                  <Route exact path="/billingsummary" element={<BillingSummary />} />
                  <Route exact path="/consumptionvariancereport" element={<ConsumptionVarianceReport />} />
                  <Route exact path="/metercommandreport" element={<MeterCommandReport />} />
                  <Route exact path="/interfaceperformancereport" element={<InterfacePerformanceReport />} />
                  <Route exact path="/amiperformancereport" element={<AMIPerformanceReport />} />
                  <Route exact path="/energyperformancereport" element={<EnergyPerfReport />} />
                  <Route exact path="/energyauditlossreport" element={<EnergyAuditLossReport />} />


                  <Route exact path="/saidireport" element={<SAIDIReport />} />
                  <Route exact path="/saifireport" element={<SAIFIReport />} />
                  <Route exact path="/caidireport" element={<CAIDIReport />} />
                  <Route exact path="/caifireport" element={<CAIFIReport />} />
                  <Route exact path="/maifireport" element={<MAIFIReport />} />


                  <Route exact path="/excessivemissingreadsreport" element={<Excessivemissingreadsreport />} />
                  <Route exact path="/missingintervalagingreport" element={<MissingIntervalAgingReport />} />
                  <Route exact path="/missingreadsreport" element={<MissingReadsReport />} />
                  <Route exact path="/dailydatacollectionreport" element={<DailyDataCollectionReport />} />
                  <Route exact path="/estimationfailuredetailreport" element={<EstimationFailureDetailReport />} />
                  <Route exact path="/reconciliationreport" element={<ReconciliationReport />} />
                  <Route exact path="/physicalmetereventsreport" element={<PhysicalMeterEventsReport />} />
                  <Route exact path="/interfaceexceptionreport" element={<InterfaceExceptionReports />} />
                  <Route exact path="/mdmperformancereport" element={<MDMPerformanceReport />} />
                  <Route exact path="/theftflagsreport" element={<TheftFlagsReport />} />
                  <Route exact path="/unauthorizedusagereport" element={<UnauthorizedUsageReport />} />
                  <Route exact path="/revenueprotectionreport" element={<RevenueProtectionReport />} />

                  


                  <Route exact path="/exceptionreport" element={<Exceptionreport />} />
                  <Route exact path="/loadforecastingreport" element={<Loadforecastingreport />} />
                  <Route exact path="/billingdeterminantreport" element={<Billingdeterminantreport />} />
                  <Route exact path="/usermanagement" element={<Usermanagement />} />
                  <Route exact path="/groupmanagement" element={<Groupmanagement />} />
                  <Route exact path="/veerulebuilder" element={<Jsonrules />} />
                  <Route exact path="/veeruleworkflow" element={<Veeworkflow />} />
                  <Route exact path="/networkdashboard" element={<Networkdashboard />} />

                  <Route exact path="/p2p/prosumerdashboard" element={<ProsumerDashboard />} />
                  <Route exact path="/p2p/serviceprovider" element={<ServiceProvider />} />
                  <Route exact path="/p2p/consumerregistration" element={<ConsumerRegistration />} />
                  <Route exact path="/p2p/prosumerscheduling" element={<ProsumerScheduling />} />
                  <Route exact path="/p2p/realtimedashboard" element={<RealTimeDashboard />} />
                  <Route exact path="/p2p/energyblockdashboard" element={<EnergyBlockDahsboard />} />


                  <Route exact path="*" element={<NoPage />} />
                </Routes>
              </Suspense>
            </div>
          </div>

        )}
      </GoogleOAuthProvider>
    </BrowserRouter>
    </Provider>
  );
}




export default App;