import React, { useState } from "react";
import { connect } from "react-redux";
import { loginSuccess, loginFailure } from "../redux/actions/authActions";
import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";

const apiUrl = process.env.REACT_APP_API_URL;

const Login = ({ handleLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false); // State for loading spinner

  const API_KEY = "364474069693-uvs67jjv58ufjq0cadmftddl63k4mfvf.apps.googleusercontent.com";

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (username.trim() === "" || password.trim() === "") {
      alert("Please enter a valid username and password.");
      return;
    }

    setLoading(true); // Set loading to true when login request starts

    try {
      const response = await axios.post(`${apiUrl}api/auth/login`, {
        username,
        password,
      });

      setLoading(false); // Set loading to false when login request completes

      if (response.data.status === 401) {
        // Handle 401 Unauthorized error here
        console.log("Unauthorized access");
        setErrorMessage("Invalid Username or Password");
        return;
      }

      const authToken = response.data.token;
      const refreshToken = response.data.refreshToken;
      handleLogin(authToken, refreshToken);
    } catch (error) {
      console.error(error);
      setLoading(false); // Set loading to false in case of error
      alert("Invalid username or password.");
    }
  };

  return (
    <div className="loginBackground d-flex">
      <div className="loginSection text-center">
        <div className="loginForm shadow-lg pt-4">
          <img src="../../assets/images/logo_black.svg" className="img-fluid mb-4" alt="voltreum" />
          <h5>Sign In To Voltreum MDMS</h5>

          <form onSubmit={handleSubmit} className="col-10 p-2">
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            <div className="form-group " style={{ textAlign: "left" }}>
              <label htmlFor="username">Username:</label>
              <input
                className="form-control block"
                type="text"
                id="username"
                value={username}
                onChange={handleUsernameChange}
              />
            </div>
            <div style={{ textAlign: "left" }} className="mb-3">
              <label htmlFor="password">Password:</label>
              <input
                className="form-control"
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
              />

              <div className="d-grid">
                <button type="submit" className="btn btn-primary btn-md mt-3" disabled={loading}>
               
                {loading ? (
  <span>
    Logging in... 
    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <span className="visually-hidden">Loading...</span>
  </span>
) : (
  "Login"
)}
                </button>
              </div>
            </div>
          </form>

          <GoogleLogin
            clientId={API_KEY} // Replace with your Google OAuth client ID
            onSuccess={(credentialResponse) => {
              console.log(credentialResponse);
            }}
            onError={() => {
              console.log("Login Failed");
            }}
            buttonText="Sign in with Google"

          />

          <p style={{ color: "#000" }} className="px-5 py-3">
            By signing in you agree to our{" "}
            <span className="text-decoration-underline">Terms of Service</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { loginSuccess, loginFailure })(Login);
