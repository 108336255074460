import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import axios from "axios";
import DarkMode from "./DarkMode";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser as faUser } from "@fortawesome/free-regular-svg-icons";
import { faBars, faBell } from "@fortawesome/free-solid-svg-icons";
import jwt_decode from 'jwt-decode';
import Avatar, {getRandomColor} from "react-avatar";

const Navbar = (props) => {
  const { handleClick } = props;

  const [credentialResponse, setCredentialResponse] = useState(null);
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState(null);
  const [sub, setSub] = useState(null);
  const { isDarkMode } = props;
  const [isValidToken, setIsValidToken] = useState(false);

  const checkTokenValidity = () => {
    const authToken = localStorage.getItem('authToken');

    if (authToken) {
      try {
        const decodedToken = jwt_decode(authToken);
        console.log("Decoded Token", decodedToken);
        setSub(decodedToken.sub); // Set the sub value from the decoded token
        const currentDate = new Date();

        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
          console.log("Token expired.");
          setIsValidToken(false);
          logout();
        } else {
          console.log("Valid token");
          setIsValidToken(true);
        }
      } catch (error) {
        console.error("Invalid token");
        setIsValidToken(false);
        logout();
      }
    } else {
      console.log("No token found.");
      setIsValidToken(false);
    }
  };

  useEffect(() => {
    // Initial check on mount
    checkTokenValidity();

    // Set interval to check token validity every 2 minutes
    const interval = setInterval(() => {
      checkTokenValidity();
    }, 2 * 60 * 1000); // 2 minutes

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const logout = () => {
    setToken(null); // clear the token in the state
    localStorage.removeItem('authToken'); // remove the token from the browser's local storage
    localStorage.removeItem('refreshToken');
    window.location.href = '/'; // redirect the user to the login page
  }

  const userExists = userData && userData.find(user => user.email === props.email);

  

  return (
    <nav className="navbar navbar-expand-lg p-3 ">
      <button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mb-2 mb-lg-0 topnav" style={{ paddingLeft: "20px" }}>
          <li className="nav-item mx-2">
            <Link className="nav-link" onClick={handleClick}>
              <FontAwesomeIcon icon={faBars} />
            </Link>
          </li>
          <li className="nav-item mx-2">
            <NavLink className="nav-link" to="/dashboard">360 Dashboard</NavLink>
          </li>
          <li className="nav-item mx-2">
            <NavLink className="nav-link" to="/eventdashboard">Event Dashboard</NavLink>
          </li>
          <li className="nav-item mx-2">
            <NavLink className="nav-link" to="/telemetry">Telemetry</NavLink>
          </li>

          <li className="nav-item mx-2">
            <NavLink className="nav-link" to="/p2p/prosumerdashboard">Prosumer </NavLink>
          </li>
          <li className="nav-item mx-2">
            <NavLink className="nav-link" to="/p2p/consumerregistration">Consumer </NavLink>
          </li>
          <li className="nav-item mx-2">
            <NavLink className="nav-link" to="/p2p/serviceprovider">Service Provider</NavLink>
          </li>
        </ul>
        <ul className="navbar-nav ms-auto me-3 align-items-start">
          <li className="nav-item me-2 border border-secondary rounded-circle text-center" style={{ 'width': '30px', 'height': '30px' }}>
            <DarkMode isDarkMode={props.isDarkMode} setIsDarkMode={props.setIsDarkMode} />
          </li>
          <li className="nav-item me-2 border border-secondary rounded-circle text-center" style={{ 'width': '30px', 'height': '30px' }}>
            <FontAwesomeIcon icon={faBell} style={{fontSize:'16px', padding:'5px'}}  />
            
          </li>
          <li >
          {sub && (
                <Avatar 
                name={sub} 
                size="30" 
                round={true} 
                color="#8B56FE" 
              />
            )}
          </li>
          <li className="nav-item">
            <Link to='/' className="nav-link">{props.name}</Link>
          </li>
          <li className="nav-item">
            <a href="/" className="nav-link" onClick={logout}>Logout</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
