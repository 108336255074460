import React from 'react'

export default function NoPage() {
  return (
    <div className='container'>
      <div className=" main-card py-3 px-3">
        <div className='widget-card  mb-4 d-flex flex-column justify-content-center align-items-stretch'>
          <h5 className='d-flex justify-content-center mb-5'> Oops Page not found</h5>
          <div className='col-12 text-center'><img src='../../assets/images/404.png'  /></div>
        </div>
      </div>
    </div>
  )
}
