export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const SET_MONTHLY_DATA = 'SET_MONTHLY_DATA';
export const SET_DAILY_DATA = 'SET_DAILY_DATA';
export const SET_OUTAGE_DATA = 'SET_OUTAGE_DATA';
export const SET_ONDEMAND_DATA = 'SET_ONDEMAND_DATA';
export const SET_PERFORMANCE_DATA = 'SET_PERFORMANCE_DATA';
export const SET_DEVICES = 'SET_DEVICES';
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const SET_DEVICE_AS_DEFAULT = "SET_DEVICE_AS_DEFAULT";

export const FETCH_ASSETS_SUCCESS = "FETCH_ASSETS_SUCCESS";
export const DELETE_ASSET_SUCCESS = "DELETE_ASSET_SUCCESS ";

export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_FIELD ="UPDATE_USER_FIELD";

export const FETCH_DEVICES_REQUEST = "FETCH_DEVICES_REQUEST";
export const FETCH_DEVICES_SUCCESS = "FETCH_DEVICES_SUCCESS";
export const FETCH_DEVICES_FAILURE = "FETCH_DEVICES_FAILURE";